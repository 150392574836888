<template>
  <el-card shadow="hover" :body-style="{ padding: '20px' }">
    <div
      class="d-flex justify-content-between align-items-center"
      slot="header"
    >
      <h5>{{ $route.meta.title }}</h5>
      <div class="d-flex justify-content-end align-items-center">
        <el-input
          placeholder="关键词搜索"
          size="small"
          v-model="params.keyword"
        >
        </el-input>
      </div>
    </div>

    <div v-loading="loading">
      <el-table :data="lists">
        <el-table-column label="视频" width="120">
          <template slot-scope="scope">
            <router-link :to="'/video/detail/' + scope.row.id" target="_blank">
              <img
                v-if="scope.row.thumbData"
                :src="viewThumb(scope.row.thumbData.thumb, 'small')"
                width="120"
            /></router-link>
          </template>
        </el-table-column>
        <el-table-column prop="title">
          <template slot-scope="scope">
            <router-link :to="'/video/detail/' + scope.row.id" target="_blank">
              {{ scope.row.title }}
            </router-link>
          </template>
        </el-table-column>
        <el-table-column label="公开范围">
          <template slot-scope="scope">
            <span v-if="scope.row.is_publish == 1">
              <i class="el-icon-document"></i>
              草稿</span
            >
            <span v-if="scope.row.is_publish === 0">
              <i class="el-icon-view text-success"></i>
              公开</span
            >
            <span class="text-gray-6">
              {{ scope.row.is_original ? "转载" : "原创" }}
            </span>
            <div v-if="scope.row.race_id > 0">
              <i class="el-icon-s-data text-info"></i>

              参赛视频
            </div>
          </template></el-table-column
        >
        <el-table-column label="栏目">
          <template slot-scope="scope">
            {{ scope.row.category ? scope.row.category.name : "" }}
          </template></el-table-column
        >

        <!-- <el-table-column prop="fav" label="顶/踩" width="180" sortable>
          <template slot-scope="scope">
            顶：{{ scope.row.fav }}
            <div>踩：{{ scope.row.unfav }}</div>
            <div>比：{{ favVsUnfav(scope.row) }}</div>
          </template>
        </el-table-column> -->
        <el-table-column prop="pv" label="流量" width="180" sortable>
          <template slot-scope="scope">
            观看：{{ scope.row.pv }}
            <div>评论：{{ scope.row.comment }}</div>
          </template>
        </el-table-column>
        <el-table-column prop="createTime" label="日期" width="180" sortable>
        </el-table-column>
        <el-table-column prop="status" label="审核状态">
             <template slot-scope="scope">
                <div>
                  <span v-if="scope.row.status === 3" class="failure-reason" @click="showFailureReason(scope.row)">
                    {{ statusCn(scope.row.status) }}
                  </span>
                  <span v-else>
                    {{ statusCn(scope.row.status) }}
                  </span>

                   <el-dialog
                      title="失败原因"
                      :visible.sync="dialogVisible"
                      width="400px"
                      :append-to-body="true"
                      custom-class="custom-dialog"
                    >
                      <div v-html="failureReason"></div>
                      <span slot="footer" class="dialog-footer">
                        <el-button @click="dialogVisible = false">确定</el-button>
                      </span>
                    </el-dialog>
                </div>
              </template>
        </el-table-column>
        <el-table-column label="操作" width="180">
          <template slot-scope="scope">
            <el-button
              type="primary"
              icon="el-icon-edit"
              size="mini"
              @click="edit(scope.row)"
              >编辑</el-button
            >
            <el-button
              type="danger"
              icon="el-icon-close"
              size="mini"
              @click="del(scope.row, scope.$index)"
              >删除</el-button
            >
          </template></el-table-column
        >
      </el-table>
    </div>

    <div class="text-center mt-20" v-if="params.pages > 1">
      <el-pagination
        @size-change="pageSizeChange"
        @current-change="pageChange"
        :current-page="params.page"
        :page-size="params.pageSize"
        layout="total, prev, pager, next, jumper"
        :total="params.total"
      ></el-pagination>
    </div>
  </el-card>
</template>

<script>
export default {
  name: "List",
  data() {
    return {
      loading: false,
      category: [],
      lists: [],
      searchTimer: null, //搜索计时器
      params: {
        category_id: "",
        total: 0,
        page: 1,
        pageSize: this.$config.pageSize,
        keyword: "",
        pages: 1,
      },
      dialogVisible: false,
      failureReason: "",
    };
  },
  watch: {
    "params.keyword": {
      handler: function () {
        if (this.searchTimer) {
          clearTimeout(this.searchTimer);
        }
        this.searchTimer = setTimeout(() => {
          this.getList();
        }, 1000);
      },
    },
  },
  created() {
    this.params.category_id = this.$route.meta.category_id;

    this.getList();
  },
  mounted() {},
  methods: {
    getList(page) {
      this.params.page = this.$utils.toInt(page) || 1;
      this.loading = true;
      this.params.user_id = this.user.id;
      this.$api.ucenter.Video.lists(this.params)
        .then((res) => {
          this.loading = false;
          this.lists = res.data.lists;
          this.params.total = res.data.total;
          this.params.pages = res.data.pages;
        })
        .catch(() => {
          this.loading = false;
        });
    },

    // 分页点击
    pageChange(page) {
      this.getList(page);
    },
    // 点击分页大小
    pageSizeChange(pageSize) {
      this.params.pageSize = pageSize;
      this.getList(this.page);
    },

    edit(item) {
      this.$router.push({
        path: "/ucenter/publish",
        query: {
          id: item.id,
        },
      });
    },
    del(item, i) {
      this.$utils.confirm(
        `确定要删除"${item.title}吗？本操纵不可逆，请谨慎操作"`,
        () => {
          this.$api.ucenter.Video.del({ id: item.id })
            .then((res) => {
              this.$delete(this.lists, i);
              this.params.total--;
              if (this.lists.length < 1) {
                this.getList();
              }
              this.$utils.success("删除成功");
            })
            .catch(() => {
              this.loading = false;
            });
        },
        () => {}
      );
    },
    statusCn(status) {
      switch (status) {
        case 0:
          return "未审核";
        case 1:
          return "审核中";
        case 2:
          return "审核成功";
        case 3:
          return "审核失败";
        case 4:
          return "审核失败";
        case 5:
          return "审核失败";
        case 6:
          return "审核失败";
        default:
          return null;
      }
    },
    showFailureReason(row) {
      let reason = "";
      if (row.terror_desc) {
        reason += "涉暴：" + row.terror_desc + "<br>";
      }
      if (row.politician_desc) {
        reason += "涉政：" + row.politician_desc + "<br>";
      }
      if (row.pulp_desc) {
        reason += "涉黄：" + row.pulp_desc + "<br>";
      }
      if (row.copyright_desc) {
        reason += "版权问题：" + row.copyright_desc + "<br>";
      }
      this.failureReason = reason;
      this.dialogVisible = true;
    }
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-table td {
  vertical-align: top;
}
.failure-reason {
  cursor: pointer;
}
.custom-dialog {
  box-shadow: none; /* 移除阴影样式 */
}
</style>